var data = {
  'subject_order': [
    'wisdomeraplatform',
    'datapreparation',
    'woncologygeneral',
    'diagnosis',
    'drugapproval',
    'gfrpage',
    'treatmentcycleincreasepage',
    'treatmentdatechangepage',
    'treatmentdaymanagement',
    'treatmentdrugarrangepage',
    'treatmentdrugcycledayarrangement',
    'treatmentlist',
    'treatmentschemadrugs',
    'treatmentschemaselection',
    'unittreatmentlist',
    'install',
    'lisans_info',
  ],
  'subjects': {
    'lisans_info': {
      'label': 'Lisans Bilgileri',
      'work_list': [{
          'label': 'Kullanılan merkez bazlı (örneğin her bir hastane için bir adet) lisanslama yapılmaktadır. Her woncology uygulamasının aktif olduğu merkezde bir adet lisans olması gerekmektdir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        },
        {
          'label': 'Ürün kod şablonu özelliği taşımaktadır. Kod şablonları HBYS sistemlerinde gerekli yönergelerle geliştirildikten sonra kullanılabilmektedir',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        },
      ]
    },
    'install': {
      'label': 'Kurulum',
      'work_list': [{
          'label': 'Woncology klasöründeki README.md dosyası kurulumda yol göstermektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        },
        {
          'label': 'NPM (Node Package Modules) uygulaması cihazda yok ise kurulumu yapılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        },
        {
          'label': 'Kurulum yapmak için aşağıdaki linke tıklayınız.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': 'https://nodejs.org/en/download/'
        },
        {
          'label': 'Woncology ürünü kurulacak cihaza yüklenir. Web tabanlı bir uygulama olduğu için Windows, Linus, MacOs işletim sistemlerinde çalıştırılabilmektedir. Ürün satın alma işlemi için iletişime geçmek için lütfen aşağıdaki linke tıklayınız.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': 'https://www.wisdomera.io/#/web/contact'
        },
        {
          'label': 'Terminal açılarak woncology klasörünün içerisine girilir. Aşağıdaki örnekte ürün c:\ konumu altına eklenmiştir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': 'c:\woncology',
          'link': ''
        },
        {
          'label': 'Woncology ürün kurulum işlemi için aşağıdaki komutu yazıp enter yapılarak çalıştırılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': 'npm install',
          'link': ''
        },
        {
          'label': 'Uygulamayı localhost:8080 linkinde çalıştırmak için terminal açılarak woncology klasörünün içerisine girilir ve aşağıdaki kod çalıştırılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': 'npm run serve',
          'link': ''
        },
        {
          'label': 'Uygulama çalıştırıldığında web browser (chrome, firefox v.b) açılarak localhost:8080 yazılarak uygulama çalıştırılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': 'localhost:8080',
          'link': ''
        },
      ]
    },
    'datapreparation': {
      'label': 'Data Preparation Role',
      'work_list': [{
          'label': 'WisdomEra is a professional actor for data governance with client id based database layer relations, especially in the area of healthcare.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        }, {
          'label': 'WisdomEra is a professional actor for data quality especially in healthcare data. Data cleansing and profiling is done by WisdomEra Ecosystem applications and teams.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        }, {
          'label': 'WisdomEra is a professional actor for data ingestion and data remodelling by today’s highly available NOSQL technologies especially in healthcare.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        }, {
          'label': 'WisdomEra is a professional actor for data analytics by Wanalyzer which includes statistical analysis, machine learning solutions, complex mathematical functions for data remodelling and Walgorithm which triggers algorithms with Wruleengine.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': '',
          'code': '',
          'link': ''
        },
      ]
    },
    'wisdomeraplatform': {
      'label': 'About',
      'work_list': [
        {
          'label': 'WisdomEra company was founded in March 2017 by Dr Levent Korkmaz. First of all, the software projects initiated in the field of oncology that support and manage the treatment management of cancer patients were later transformed into a large platform containing many integrated data analytics applications.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'WisdomEra carries out training, consultancy and development activities for projects and infrastructure services, which include data analytics, decision support system algorithms, and artificial intelligence-based applications, and for which it offers service-based services and software licenses.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'WisdomEra has systems and tools that perform data preprocessing and presentation of preprocessed data to modeling on a multifunctional and service-based basis.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'woncologygeneral': {
      'label': 'Vücut yüzey alanı',
      'work_list': [{
          'label': 'Onkoloji tedavilerinde ilaçların dozlarında hesaplamalarda vücut yüzey alanı değeri kullanılmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Vücut yüzey alanı değerinin en güncel olduğu değerden ilaç dozlarının hesabı yapılması gerekmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hastanın kilo değişimiyle birlikte ilaç dozları da değişmektedir. Bu nedenle vücut yüzey alanı hesaplamalarının güncel olması çok önemlidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': '1 ay geçtikten sonra hastanın kilo kaybı nedeniyle vücut yüzey alanı değişebilir. Bu nedenle yeni gelen tedavileri için dozlar anlık olarak yeniden hesaplanır. Yeni hesaplanan doz değişimleri gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': '1 ay geçtikten sonra hastanın kilo kaybı nedeniyle vücut yüzey alanı değişebilir. Bu nedenle yeniden kilo boy değerini girmesi kullanıcıya önerilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Son girilen kilo boy bilgisi 30 günden daha fazla geçtiyse ve tedavisi vücut yüzey alanı gerektiren bir ilaç onaylanacaksa vücut yüzey alanını yenilemesi için kullanıcının uyarılması önemlidir. Zorunlu olarak değiştirmesi gerekmemektedir. Bilgilendirme amaçlı uyarı yapılmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yeni VYA oluştur butonuna tıklandığında açılan bir ön ekran (modal) açılır.',
          'status': 0,
          'button_name': 'Yeni VYA',
          'trigger_point': '',
          'method_name': 'f_createNewBodySurfaceArea'
        },
        {
          'label': 'Yeni VYA oluşturulurken tarih seçimi alanında o anın tarihi ve saati seçilir. Fakat kullanıcı bu tarihi değiştirebilir. Eski tarihli bir değer de girebilir. İleri tarihli bir değer giremez',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'VYA hesaplama ekranında kilo ve boy değeri kullanılarak VYA hesaplanır. Değerleri kullanıcı yazarken hesaplama frontendde anlık olarak yapılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Kilo ve boy girilince otomatik olarak düzeltilmiş kilo hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': 'f_calculateAdjustedWeight'
        },
        {
          'label': 'Kilo ve boy girilince otomatik olarak ideal kilo hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': 'f_calculateIdealWeight'
        },
        {
          'label': 'Kilo ve boy girilince otomatik olarak vücut kitle indeksi hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': 'f_calculateBodyMassIndex'
        },
        {
          'label': 'VYA kaydetme işleminde tüm değişkenler hasta id değeri ile ilişkilendirilen tabloya kayıt oluşturulur.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': 'f_saveBodySurfaceArea'
        },
      ]
    },
    'diagnosis': {
      'label': 'Tanı seçimi',
      'work_list': [{
          'label': 'Yeni tedavi oluşturma ekranı açılırken seçili hastanın c kodu (kanser tanısı) olan tanı listesi de getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tanı listesinden ilgili seçimi kullanıcı yapar.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Zorunlu alan olarak dizayn edilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Eğer daha önceden C kodu eklenmiş bir tanısı yok ise o zaman hastaya C kodu içeren bir tanı eklemesi için “+” butonuna tıklar.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Burada genellikle c kodlu tanıyı kullanıcı seçmesi gerektiğinden açıllan modalde c kodu olan tanı listesi gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tercihen diğer tanı kodları da seçilebileceğinden ön tanımlı olarak c kodlu tanıları listelemek ve gerekirse diğer tüm tanıları kullanıcının seçmesi sağlanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hastaya planlanan tedaviye ait ilaçların statelerinin değiştirilmesi işlemidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'drugapproval': {
      'label': 'İlaç onay',
      'work_list': [{
          'label': 'Adımlar sırayla ilerletilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bunun için stateler arasındaki ilişki ve adım sıralaması kontrol edilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Burada 32 buton işlemi mevcuttur.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tek ilaç için seçildiğinde sadece ilgili gündeki seçilen ilaca işlem yapılırken, gün fonksiyonlarındaki bu butondaki ilgili state seçildiğinde seçilen gündeki tüm ilaçlara aynı işlem uygulanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlaç tedavi onay adımları ilaç bazlı olarak ya da gün bazlı olarak gündeki tüm ilaçlar için toplu olarak yapılabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'gfrpage': {
      'label': 'Glomerüler filtrasyon hızı (GFR)',
      'work_list': [{
          'label': 'İlaçların dozlarında hesaplamalarda glomerüler filtrasyon hızı (GFR) değeri kullanılmaktadır. Örneğin auc formülünde ilgili değer kullanılarak tedavi dozları belirlenen ilaçlar bulunmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Güncel kreatinin değeri ve kilo değişimleriyle değişen bir bilgi olduğundan güncel olması gerekmektedir. Örneğin kreatinin değeri normalin üzerine çıkan bir hastada ilaç dozları değişecektir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hastada toksik etkilerin oluşmaması için güncel bir GFR hesaplama değerinin kullanılması konusunda kullanıcı tedavi onayı yapacağında uyarılmalıdır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hastanede yapılan kreatinin değeri zorunlu olmamalıdır. Çünkü hasta dış bir merkezde kreatinin değerini yaptırmış ve hastaneye gelmiş olabilir. Bu nedenle dış merkezden gelen bir kreatinin değeri de kullanılarak gfr hesaplanabilmelidir. Bu nedenle, kreatinin laboratuar değeri ister o anda hastanede yapılsın ya da dış merkezde yapılsın farketmez, hesaplamada kullanılan kreatinin değeri de eklenebilmelidir. Manuel kreatinin (mg/dl) değeri şeklinde laboratuar test listesine test eklenir. Kullanıcı kreatinin eklemek istediği zaman bu değeri ekler. Otomatik laboratuar çıktıları süreçlerinde de kreatinin değerinin farklı kitten verilen çıktılarıyla birlikte birden fazla değişken tipinde çıktıları olabilmektedir. Bu nedenle GFR hesaplaması yapılacağında kreatinin sorgusu yaparken seçilen kreatinin testleri kullanılarak ekrana son güncel tarihli kreatinin değeri getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'GFR hesaplanabilmesi için sistemde en az bir adet kilo ve boy değerleri kayıtlı olmalıdır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'GFR hesaplanırken son kreatinin değeri ekrana getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'GFR ekranda gösterilirken kreatinin değeri normal üst sınır olan değerden fazla ise bu durumda kırmızı olarak gösterilerek kullanıcının dikkatli olması sağlanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yeni kreatinin butonuna tıklandığında açılan bir ön ekran (modal) açılır.',
          'status': 0,
          'button_name': 'Yeni Kreatinin',
          'trigger_point': '',
          'method_name': 'f_createNewCreatinin'
        },
        {
          'label': 'Yeni kreatinin eklerken tarih alanında o anın tarihi ve saati seçilir. Fakat kullanıcı bu tarihi değiştirebilir. Eski tarihli bir değer de girebilir. Gelecek tarihli veri eklenemez.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Cockcroft Gault GFR değeri hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': 'GFR hesaplama testi seçimi değiştirildiğinde tetiklenir',
          'method_name': 'f_calculateGfrCockcroftGault'
        },
        {
          'label': 'MDRD GFR değerini hesaplama, MDRD GFR değeri hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': 'GFR hesaplama testi seçimi değiştirildiğinde tetiklenir',
          'method_name': 'f_calculateGfrMdrd'
        },
        {
          'label': 'Kilo tipi gerçek kilo, ideal kilo, düzeltilmiş kilo değerlerinden seçim yapıldığında, seçili olan gfr tipine göre yeniden GFR değeri hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': 'Kilo Tipleri Listesinden kilo seçimi değiştirildiğinde tetiklenir',
          'method_name': 'f_selectThisWeightType'
        },
      ]
    },
    'treatmentcycleincreasepage': {
      'label': 'Siklus artırma',
      'work_list': [{
          'label': 'Seçili ilaçlara, belirlenen peryod ve günlerde son siklustaki dozları dikkate alınarak belirlediğiniz siklus sayısınca ekleme yapılmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Son tedavi siklusundaki ilaçlar listesi, period ve gün bilgileri yeni period ekleme sayfasında açılır',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Burada period ve günler düzenlenebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Genellikle period ve günlerde ek düzenleme yapılmadan yeni sikluslar tedaviye eklenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yeni siklus eklenince, ilaçlara ait tarihler hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Her bir ilaç başlama tarihi ayrı ayrı olarak hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yeni her bir ilaca ait tarih hesaplanırken ilgili ilacın son tarih değeri ve periodu baz alınarak başlangıç tarihi baz alınır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlaca ait tarih hesaplanırken ve yeni günlere ait ilaçlar ekleme işlemi PatientTreatmentDrugsApplication tablosunda gerçekleştirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Ekleme işlemi yapılırken seçili tedaviye ait id baz alınarak yapılarak bu tedavi idsi ile ilişkili olarak yeni ilaç tarihleri ve diğer doz gibi bilgileri de son ilaç değerleri baz alınarak eklenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Kullanıcı bu sikluslarda daha sonra ilaç doz düzenlemeleri yapmak istediğinde doz düzenleme ekranı daha sonra açılarak ayrıca yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Ekrandaki ilaçlar son siklusa ait ilaçları listelemektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu ilaç listesi PatientTreatmentDrugsApplication tablosundan getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu ilaçlar otomatik olarak checkli getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Kullanıcı isterse ilaçlardan istediğinde seçimi kaldırarak siklus ekleme işlemi için uygun ilaçları belirler.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün ekleme ya da değişimi yapılmak istendiğinde bu ekranda yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentdatechangepage': {
      'label': 'Tedavi tarih öteleme',
      'work_list': [{
          'label': 'Hastaya planlanan tedavinin tarihleri kullanıcı tarafından ötenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu bazen bir ilaç için ya da toplu olarak o gündeki tüm ilaçlar için yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Genellikle toplu olarak yapıldığından öncelikle toplu olarak tarih öteleme açılır penceresi detaylı tarih düzenleme ekranından ayrıca dizayn edilmelidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tarih öteleme işlemi ilgili gün başlığının olduğu yerde bulunmaktadır. Böylece bugündeki ilaçlar ötelenmiş olacaktır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Öteleme yapılırken PatientTreatmentDrugsApplication tablosundaki ilgili gündeki ilaca ait uygulama tarihleri bilgisi [day_date] değiştirilir. Bu hücrede bulunan tarih kullanıcının frontendde belirlediği tarihe ötelenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Mevcut seçili tarih bilgisi gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu tarihin yeni hangi tarihe ötelenmek istendiği bilgisi seçilir. İleri tarihli olarak seçtirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün değişimini gün olarak girmek isteyen kullanıcı gün adedi olarak kaç gün alanına girer. Bu sırada yeni tarih otomatik olarak hesaplanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tarih neden değiştirilmek isteniyorsa belirtilen checkbox listesinden seçim yapılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tarih değişimiyle ilgili bir not eklenmek istendiğinde de eklenebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tarih değişimiyle ilgili notlar ve seçilen seçenekler o gündeki ilaçlara ait notlar tablosuna (PatientTreatmentDrugsApplicationNotes) eklenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bir gündeki tanımlı ilaçlar PatientTreatmentDrugsApplication tablosunda tekil olarak oluşturulmaktadır. Bu tablodaki id ile ilişkili PatientTreatmentDrugsApplicationNotes tablosunda çoklu not ya da ilaç doz değişim sebebi eklenebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentdaymanagement': {
      'label': 'Tedavi gün yönetimi',
      'work_list': [{
          'label': 'Hastaya planlanan tedavinin hangi günlerde uygulanacağı bu ekranda görüntülenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yine hastanın geçmiş, bugün ve gelecek tedavilerinin görüntülenmesi sağlanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hasta bazlı olarak seçilen tedavisine ait ilaçlarının yönetimini doktor, hemşire, eczane yönetimi tek ekran olarak buradan yönetilmesi sağlanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Güncel kreatinin değeri ve kilo değişimleriyle değişen bir bilgi olduğundan güncel olması gerekmektedir. Örneğin kreatinin değeri normalin üzerine çıkan bir hastada ilaç dozları değişecektir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu nedenle hastada toksik etkilerin oluşmaması için güncel bir GFR hesaplama değerinin kullanılması konusunda kullanıcı tedavi onayı yapacağında uyarılmalıdır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu nedenle yeni tedavi oluşturma sayfasında ilgili değerlerin girilmesi sağlandığı gibi tedavi listesi sayfasında da aktif olarak güncel kilo, boy, kreatinin gibi veriler üzerinden tedavinin takibi önemlidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavi listesi sayfasında yeni eklenen kilo, boy, kreatinin gibi değerlerle yeni dozlar ve yüzde değişimleri kullanıcıya gösterilmektedir. % 10 gibi fazla değişen değerler için de uyarı verilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavi günlerinde öteleme ya da geriye çekme gibi işlemlerin yapılabilir olması gereklidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün bazlı olarak uygulanacak ilaç dozları düzenlenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Belirlenen dozlar sonraki günlere de otomatik olarak kullanıcı talep ederse otomatik olarak atanabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedaviye ait uygulama bilgileri yazdırılabilir. İlaç uygulama sırasında ilgili kağıt üzerinde yazdırılan doküman kullanıcı tarafından kullanılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlac ilgili gün için not eklenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Her bir ilaç için ayrı ayrı ya da toplu olarak x kadar siklus otomatik olarak artırılabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Aynı gündeki tüm ilaçlar toplu olarak onaylanabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Her bir ilaç ayrı ayrı onaylanabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'O gün uygulanması uygun olmayan bir tedavi atlanabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün iptal edilerek silinebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Her bir ilaç için uygulama durumları farklı state için farklı renklerle ifade edilmektedir. Böylece kullanıcının kolayca hangi ilaçlara işlem yapacağı ya da yaptığı bir bakışta anlaşılabilmektedir. İlaca ait state durumu da hızlıca anlaşılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gerekmediği sürece ekranda sadece ilaç ve planlanan doz bilgisi gösterilmektedir. Böylece ekranda gereksiz kalabalık veri görüntülenmemektedir. Akordiyon modeliyle isterse ilacın daha detaylı bilgilerini kullanıcı görüntüleyebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hasta tedavi listesi sayfasına girildiğinde hasta için hazırlanmış eski ve yeni tüm tedavileri tarih bazlı olarak sıralanmaktadır. PatientTreatment tablosundaki seçilen id ile ilişkili olarak PatientTreatmentDrugsApplication tablosundaki gün bazlı bilgiler tarih sıralamasına dikkat edilerek getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Böylece hem daha önceki uygulanan tedaviler görüntülenebilmekte hem de yeni aktif tedavileri uygulanabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün başlığı tedavinin kaçıncı siklus ve kaçıncı gününde olduğunu ifade edilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Belirtilen günün tarihi gün/ay/yıl şeklinde yazdırılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Belirtilen günün bugünden kaç gün farkı olduğu ifade edilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Belirtilen gün bugün ise yeşil değil ise kırmızı olarak ifade edilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedaviye ait gündeki tüm ilaçlar ile ilgili ilaç düzenleme, tarih öteleme gibi işlemlerin yapılmasını sağlayan buton oluşturulur.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu buton bir butongroup olarak çalışır. İçerisinde ilgili güne ait tarih ötelenebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Belirtilen gün yazdırılabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlaçlara ait dozlar ve uygulama ile ilgili diğer bilgileri düzenlenebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün tedaviden silinebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün içerisinde tüm ilaçlara henüz işlem yapılmadıysa, diğer bir ifadeyle tüm ilaçlar uygulanmamış durumdaki renkte ya da aynı renk yani uygulama adımında ise ok ile gösterilen buton gün toplu işlemleri için aktif olmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Burada 32 buton işlemi mevcuttur. Bu onay işlemleri tek ilaç bazlı alanda da aynı şekilde mevcuttur. Tek ilaç için seçildiğinde sadece ilgili gündeki seçilen ilaca işlem yapılırken, gün fonksiyonlarındaki bu butondaki ilgili state seçildiğinde seçilen gündeki tüm ilaçlara aynı işlem uygulanır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlgili güne tıklandığında açılan akordiyon alanında aşağıdaki sırayla her bir ilaç gösterilir. Her bir ilaç için sağ tarafta da ilgili ilaca ait onay işlemlerine ait son bilgileri gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Sol tarafta, ilacın adı + sıklık değeri + parantez aç + mayi adı + mayi miktarı + uygulama süresi + infüzyon hızı + damla/dk değeri + uygulama şekli + başlama saati',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Sağ tarafta, son işlem altında ilaca ait durum bilgisi gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Altında ilaca ait bugün için bir not var ise bu not bilgisi gösterilir. Not bilgisinin kim tarafından eklendiği bilgisi kullanıcı adı ve soyadı ile birlikte gösterilir. Yeni not ekleme, silme ve düzenleme butonları oluşturulur.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentdrugarrangepage': {
      'label': 'Tedavi ilaç uygulama düzenleme',
      'work_list': [{
          'label': 'Hastaya planlanan tedavideki ilaç dozları kullanıcı tarafından düzenlenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu bazen bir ilaç için ya da toplu olarak o gündeki tüm ilaçlar için ya da sonraki günler için de yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Genellikle toplu olarak yapılmaktadır. “Yaptığım deği̇şi̇kli̇kleri̇ bugünden sonraki̇ tüm günlere de uygula” şeklinde seçili olarak default olarak ekran açılmaktadır. Böylece kullanıcı isterse sonraki günlerde doz değişimi yapmak istemiyorsa bu checki kaldırmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Düzenleme yapılırken PatientTreatmentDrugsApplication tablosundaki ilgili gündeki ve sonrası için de dendiyse sonraki tarihlerdeki seçili ilaçlara, ilaca ait uygulama bilgileri değiştirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Düzenleme ekranında ilacın dozları, uygulama şekli gibi bilgiler değiştirilebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Gün başlığında ilgili ekran açıldığından o gündeki ilaçlara ait düzenleme yapılmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'O gündeki ilaçlara ait doz düzenlemeleri yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'O gündeki ilaçların hepsine düzenleme istenmiyor ise ilaçlar listesinden ilgili ilacın checki kaldırılabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yüzde belirterek dozlar otomatik olarak düzenlenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yapılan değişikliklerin daha sonraki günlerdeki aynı ilaçlara da uygulanması isteniyor ise yukarıdaki check bilgisi seçili olarak kalmaktadır. Bu değişken default olarak checkli olarak açılmaktadır. Genellikle bu şekilde kullanılmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlaçlarda düzenleme yapıldığında düzenleme nedeni de seçilebilmektedir. Bu düzenleme nedenleri birden fazla olabileceğinden seçilerek ekleme şeklinde yapılır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Doz düzenleme notu da eklenebilir. Bu bilgiler PatientTreatmentDrugsApplicationNotes tablosuna kaydedilir. PatientTreatmentDrugsApplication.Id ile ilişkilendirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Seçilen gündeki ilaçlar listelenir. Kaçıncı siklus ve hangi gündeki ilaç olduğu gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlacın dozu, birimi, planlanan doz, uygulama şekli, sıklık bilgisi, sıvı tipi, sıvı miktarı, uygulama süresi, infüzyon hızı ve ilaç sonrası bekleme süresi değiştirilebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentdrugcycledayarrangement': {
      'label': 'Tedavi gün siklus düzenleme',
      'work_list': [{
          'label': 'Tedavideki ilaçlara ait henüz onaylanmamış tedavilere ait günler, ilaç bazlı olarak her bir siklustaki günler ayrı ayrı olarak düzenlenebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Seçili tedaviye ait ilaçlar ilaç bazlı olarak günleri ve tarihleri gösterilecek şekilde PatientTreatmentDrugsApplication tablosundan listelenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Kullanıcı günleri silebilir, gün değerini değiştirerek ilaca ait uygulama tarihini değiştirebilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Onaylanmış statustaki tarihlerdeki ilaçlara ait günler değiştirilemez şekilde kullanıcıya gösterilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlaca ait siklus artırma işlemi tekil olarak yapılabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Konsolu f12 ile açarak tetiklemelerin console.log larını izleyebilirsiniz.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentlist': {
      'label': 'Hasta tedavi listesi',
      'work_list': [{
          'label': 'Hastaların daha önce aldığı tedavi listesinin görüntülenmesi onkoloji hastalarının en önemli konularından birisidir. Bu nedenle önceki uygulanan tedavilerin ve aktif tedavilerin listelenmesi hastanın toksik durumlarının daha kolay anlaşılmasını sağlar.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Güncel kreatinin değeri ve kilo değişimleriyle değişen bir bilgi olduğundan güncel olması gerekmektedir. Örneğin kreatinin değeri normalin üzerine çıkan bir hastada ilaç dozları değişecektir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu nedenle hastada toksik etkilerin oluşmaması için güncel bir GFR hesaplama değerinin kullanılması konusunda kullanıcı tedavi onayı yapacağında uyarılmalıdır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu nedenle yeni tedavi oluşturma sayfasında ilgili değerlerin girilmesi sağlandığı gibi tedavi listesi sayfasında da aktif olarak güncel kilo, boy, kreatinin gibi veriler üzerinden tedavinin takibi önemlidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavi listesi sayfasında yeni eklenen kilo, boy, kreatinin gibi değerlerle yeni dozlar ve yüzde değişimleri kullanıcıya gösterilmektedir. % 10 gibi fazla değişen değerler için de uyarı verilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hasta tedavi listesi sayfasına girildiğinde hasta için hazırlanmış eski ve yeni tüm tedavileri tarih bazlı olarak sıralanmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Böylece hem daha önceki uygulanan tedaviler görüntülenebilmekte hem de yeni aktif tedavileri uygulanabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentschemadrugs': {
      'label': 'Tedavi şemaları i̇laçları',
      'work_list': [{
          'label': 'Şemalar ilaçlar ve ilaçlara ait doz bilgileri uygulama şekilleri gibi bilgileri barındırmaktadır. Bu bilgiler de tedavinin uygulama sürecinde kulanılmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavi şemasına ait ilaçlar tedavi seçildiğinde tedavi şema id ile seçilerek ekrana getirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavi şemaları sistemdeki genel kayıtlı şemalardan olabileceği gibi, kullanıcının kendisine özel olarak kaydettiği tablodan da getirilebilir. Kullanıcı şema tipini seçtiğinde bu tablo belirlenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': '...',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'treatmentschemaselection': {
      'label': 'Tedavi şemaları',
      'work_list': [{
          'label': 'Onkoloji tedavi yönetiminde yeni tedaviler kemoterapi, immünoterapi, oral hedefe yönelik tedaviler ve hormonoterapi olarak gruplandırılabilir. Genel olarak periodik olarak uygulanır. Dolayısıyla periodik olarak yönetilebilmesi çok önemlidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Dünyadaki literatürler ve klavuzlar da baz alındığında kabul görmüş dozları, uygulama şekilleri belirlenmiş tedaviler bulunmaktadır. Hazır şablonlar üzerinden tedavi başlatılması ilgili uzmanın sürecini kolaylaştırmaktadır. Ayrıca daha güvenli olmasını sağlamaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yeni tedavi oluşturma ekranı mümkün olduğunca input alanları minimum sayıda yeni açılır ekranla dizayn edilmelidir. Çünkü yeni tedavi oluşturma ekranında bir çok seçim yapılmaktadır. Kompakt bir yapı kullanıcının işlemlerini yapmasını kolaylaştırmaktadır. Böylece daha kullanıcı dostu bir yapı oluşmaktadır.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Onkoloji tedavi yönetiminde yeni tedaviler kemoterapi, immünoterapi, oral hedefe yönelik tedaviler ve hormonoterapi olarak gruplandırılabilir. Genel olarak periodik olarak uygulanır. Dolayısıyla periodik olarak yönetilebilmesi çok önemlidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Kullanıcının yeni tedavi seçimi yaparken vücut yüzey alanı, kilo, boy, kreatinin, gfr değerleri ve tarihlerini aynı ekranda görmesi önemlidir. Çünkü tedavi protokolü belirlenirken bu değerlerle ilgili de ek düzenlemeler aynı ekranda yapılabilmektedir. Örneğin gfr için ideal ağırlık kullan ya da gfr için mdrd kulan şeklinde ek seçimlerle ilaç dozları anlık değişimlerle uzman tarafından incelenmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hastaya daha önce uygulanmış eski tedavi protokollerinin de sisteme kaydedilebilmesi gereklidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Başka bir merkezde başlayan devam etmekte olan tedaviler kaydedilerek tedavinin devamı sağlanabilmelidir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': '"Tedavi burada ilaç istemi yapılarak uygulanacak" şekilde onaylama işlemleri yapılırken başvuru protokolünün açık olma zorunluluğu fonksiyonel olarak eklenebilir. Hasta dış merkezde tedavisini alarak da gelebilir. Dış merkezde aldığı tedavi için "tedavisini dış merkezde uygulanmıştır" seçimi yapılabilmektedir. Dolayısıyla hasta tedavi bütünlüğünde de bir sorun olmadan süreçler ilerletilebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
    'unittreatmentlist': {
      'label': 'Ünite tedavi listesi',
      'work_list': [{
          'label': 'Hasta bazlı tedavi yönetimleri yapılabildiği gibi, bugündeki tüm hastalara ait tedavilerin liste halinde görülebilir ve ve onay süreçlerinin yapılabilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Böylece gün içerisindeki tüm hastalara ait tedaviler toplu olarak yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tüm hastaları kemoterapi ünitesindeki yetkili olan kullanıcılar görüntüleyebilir ve işlemleri hızlıca burada gerçekleştirebilirler.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Seçilen gün bazlı olarak PatientTreatmentDrugsApplication tablosundaki tedaviler listelenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu tedaviler listelenirken default olarak bugünün tarihi seçilerek bugündeki hastalara ait tedavisi uygulanacak ilaçlar listelenir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedaviler durumları bazlı olarak filtrelenebilmektedir. Böylece ilaç hazırlama adımını gerçekleştiren kullanıcılar sadece bu adımları görüntüleyebilmektedirler.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavideki durumları değiştirilen ilaçlara ait bilgiler socket ile bu sayfası açık olanlara bildirilir. Bilirim sırasında hangi ilaçlarda durum değişimi uygulandığı bildirilir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Bu bildirim sırasında bir ses ile bildirim de sağlanarak aktif ekranı açık olan kişilerin uyarılması da sağlanabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hasta bazlı olarak kullanılan Tedavi Gün Yönetimi sayfasındaki komponent burada kullanılarak benzer fonksiyonlar hasta id, tedavi id ve ilaç id ile yönetilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Sol üstte belirtilen tarih default olarak bugün olarak otomatik olarak seçilmekedir. Bugüne ait ilaç tedavileri gösterilmektedir. Kullanıcı isterse bu tarihi değiştirebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hasta adı veya soyadı bilgileri yazıldığında listeden filtreleme yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'İlaçlar listesi burada görüntülenerek yetkili kullanıcılar ilaçlara ait durumları buradan değiştirebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Anormal laboratuarı olan tedavilere ait bilgiler burada uyarılarak görüntülenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedaviye ait hastanın tedavi listesi sayfasına bu sayfadan geçiş yapılabilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Yukarıdaki durum bilgileri seçilerek belirli durumdaki ilaçlar altta filtrelenerek listelenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Hazırlayan kullanıcı değiştirilerek tedavi listesi filtrelenebilmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
        {
          'label': 'Tedavi adedi görüntülenmektedir.',
          'status': 0,
          'button_name': '',
          'trigger_point': '',
          'method_name': ''
        },
      ]
    },
  }
};

export { data as options };

